<template>
  <sample-modal
    overlay-class="modal-opacity-bg overflow-hidden"
    container-class="rounded-20"
    @close-modal="$emit('close')"
  >
    <template #main>
      <div
        class="sm:h-665px h-screen max-h-screen w-screen md:max-w-484px md:max-h-min flex-1 md:h-auto flex flex-col sm:rounded-20 bg-woodsmoke relative justify-center"
      >
        <button
          id="close-button"
          class="cursor-pointer w-6 h-6 flex items-center justify-center absolute top-4 right-4"
          @click="$emit('close')"
        >
          <img src="@/assets/icons/close.png" alt="close" class="w-3 h-3" />
        </button>

        <div
          class="px-4 sm:px-10 sm:pt-12 sm:pb-10 flex flex-col text-center items-center justify-center w-full"
        >
          <h1 class="text-xl text-grayscale-96 leading-160 font-druk-wide">
            Send Message
          </h1>

          <div class="mt-16 md:mt-11 mb-10 md:mb-6 max-w-335px w-full flex-1">
            <input-field
              v-model:value="message"
              label="Leave your message"
              :is-mandatory="true"
              label-class="text-sm font-normal normal-case"
              input-container-class="mt-2"
              name="mainQuestion"
              type="textarea"
              class="min-h-36"
            />
          </div>

          <div
            class="mt-10 flex flex-col gap-4 font-bold text-base max-w-335px w-full flex-1"
          >
            <general-button
              :disabled="!message.length"
              @click="$emit('submit', message)"
            >
              Send Message
            </general-button>

            <general-button color="light-gray" @click="$emit('close')">
              Cancel
            </general-button>
          </div>
        </div>
      </div>
      <XposureLoader
        v-if="isLoading"
        additionalClasses="absolute top-0 left-0 w-screen h-full"
      />
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import SampleModal from "@/components/modal/SampleModal.vue";
import InputField from "@/components/layout/inputs/InputField.vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import XposureLoader from "@/components/loader/XposureLoader.vue";

defineEmits(["close", "submit"]);

const message = ref("");
// const _isLoading = ref(false);

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});
</script>
